import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/10-tips-to-learn-a-new-language",
  "date": "2019-11-18",
  "title": "10 Tips to Learn a New Language",
  "tags": ["tips"],
  "featuredImage": "feature.jpg",
  "excerpt": "If you are struggling to pick up a second language, we have some advice for you. If you currently believe that you can never become bilingual, get ready to take some notes! Here are 10 tips to learn a new language."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Tip #1. Know Your Reason`}</h2>
    <p>{`If you don’t have a good reason to learn a language, you are less likely to stay motivated over the long-run. Wanting to impress English-speakers with your German is not a very good reason — wanting to get to know a German person in their own language is another matter entirely. No matter your reason, once you’ve decided on a language, it’s crucial to commit:`}</p>
    <p>{`“OK, I want to learn this and I’m therefore going to do as much as I can in this language, with this language and for this language.”`}</p>
    <h2>{`Tip #2. Self Practice`}</h2>
    <p>{`If you have no one else to practice tour language with, there’s nothing wrong with talking to yourself in a foreign language.`}</p>
    <p>{`“It might sound really weird, but actually speaking to yourself in a language is a great way to practice if you’re not able to use it all the time.”`}</p>
    <p>{`If you don’t know how to go about learning a new language, this can keep new words and phrases fresh in your mind. It also helps build up your confidence for the next time you speak with someone.`}</p>
    <h2>{`Tip #3. Find A Partner`}</h2>
    <p>{`Finding some kind of partner will push both of you to always try just a little bit harder and stay with it.`}</p>
    <p>{`It’s a really great way of actually going about it. You have someone with whom you can speak, and that’s the idea behind learning a new language.`}</p>
    <h2>{`Tip #4. Enjoy It`}</h2>
    <p>{`Using your new language in any way is a creative act. Practice their your language by writing or reading. Think of some fun ways to practice your new language: make a radio play with a friend, draw a comic strip, write a poem or simply talk to whomever you can.`}</p>
    <h2>{`Tip #5. Keep It Relevant`}</h2>
    <p>{`If you make conversation a goal from the beginning, you’re less likely to get lost in textbooks. Talking to people is one of the best ways to learn a language, because it keeps the learning process relevant to you.`}</p>
    <p>{`You’re learning a language to be able to use it. You’re not going to speak it only to yourself. The creative side is really being able to put the language that you’re learning into a more useful, general, everyday setting — be that through writing, generally wanting to speak to people, or using it when you go abroad.`}</p>
    <h2>{`Tip #6. Learn Like A Child`}</h2>
    <p>{`This is not to say you should throw a tantrum or get food in your hair when you go out to a restaurant — but try learning the way kids do. The idea that children are inherently better learners than adults is proving to be a myth. New research cannot find a direct link between age and the ability to learn. The key to learning as quickly as a child may be to simply take on certain childlike attitudes. For instance, lack of self-consciousness,a desire to play in the language and willingness to make mistakes.`}</p>
    <p>{`We learn by making mistakes. As kids, we are expected to make mistakes, but as adults, mistakes become taboo. Think how an adult is more likely to say, “I can’t” rather than, “I haven’t learned that yet” (I can’t swim, I can’t drive, I can’t speak Spanish). To be seen failing (or merely struggling) is a social taboo that doesn’t burden children. When it comes to learning a language, admitting that you don’t know everything (and being okay with that) is the key to growth and freedom. Let go of your grown-up inhibitions!`}</p>
    <h2>{`Tip #7. Listen`}</h2>
    <p>{`You must learn to listen before you can speak. Every language sounds strange the first time you hear it, but the more you expose yourself to it the more familiar it becomes, and the easier it is to speak it properly.`}</p>
    <h2>{`Tip #8. Leave Your Comfort Zone`}</h2>
    <p>{`Willingness to make mistakes means being ready to put yourself in potentially embarrassing situations. This can be scary, but it’s the only way to develop and improve. No matter how much you learn, you won’t ever speak a language without putting yourself out there: talk to strangers in the language, ask for directions, order food, try to tell a joke. The more often you do this, the bigger your comfort zone becomes and the more at ease you can be in new situations.`}</p>
    <p>{`At the beginning you’re going to encounter difficulties: maybe the pronunciation, maybe the grammar, the syntax, or you don’t really get the sayings. But I think the most important thing is to always develop this feel. Every native speaker has a feel for his or her own language, and that’s basically what makes a native-speaker — whether you can make the language your own.`}</p>
    <h2>{`Tip #9. Watch Others Talk`}</h2>
    <p>{`Different languages make different demands on your tongue, lips and throat. Pronunciation is just as much physical as it is mental:`}</p>
    <p>{`“One way — it might sound a bit strange — is to really look at someone while they’re saying words that use that sound, and then to try to imitate that sound as much as possible. Believe me, it might be difficult at the beginning, but you will master it. It’s something that is actually quite easily done; you just need to practice.”`}</p>
    <p>{`If you can’t watch and imitate a native-speaker in person, watching foreign-language films and TV is a good learning substitute.`}</p>
    <h2>{`Tip #10. Immerse Yourself`}</h2>
    <p>{`So you’ve made the pledge. How to proceed? Is there a proper way to go about language learning? We recommends the 360° maximalist approach: no matter which learning tools you use, it’s crucial to practice your new language every single day and immerse yourself:`}</p>
    <p>{`You tend to want to absorb as much as possible right from the start. So if you learn something, you really go for it and try to use it throughout the day. As the week progresses you will try to think in it, try to write in it, try to speak to yourself even in that language. It’s about actually putting what you’re learning into practice — be that writing an email, speaking to yourself, listening to music, listening to the radio. Surrounding yourself, submerging yourself in the new language culture is extremely important.”`}</p>
    <p>{`Remember, the best possible outcome of speaking a language is for people to speak back to you. Being able to have a simple conversation is a huge reward in itself. Reaching milestones like that early on will make it easier to stay motivated and keep practicing. And don’t worry, you won’t annoy people by speaking their language poorly. If you preface any interaction with, “I’m learning and I’d like to practice…” most people will be patient, encouraging and happy to oblige.`}</p>
    <p>{`Even though there are approximately a billion non-native English-speakers around the world, most of them would rather speak their own language if given a choice. Taking the initiative to step into someone else’s language can also put them at ease and promote good feelings all around:`}</p>
    <p>{`Sure, you can travel abroad speaking your own language, but you’ll get so much more out of it being able to actually feel at ease in the place you are — being able to communicate, to understand, to interact in every situation you could possibly imagine.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      